<template>
  <SlAccordion
    class="error-block"
    full-height
  >
    <template #trigger="{ isOpen, toggle }">
      <div
        class="error-block__title body-3 grey-80"
        @click="tableRows.length && toggle()"
      >
        <icon
          data="@icons/error_circle.svg"
          class="fill-off size-20 color-accent-red-90"
        />
        {{ title }}
        <icon
          v-if="tableRows.length"
          data="@icons/chevron_down.svg"
          class="fill-off size-20"
          :class="{
            'rotate-180': isOpen
          }"
        />
      </div>
    </template>
    <SlTable
      v-if="tableRows.length"
      :id="title"
      :headers="tableHeaders"
      :rows="tableRows"
      unique-key="id"
      header-unique-key="key"
      header-name-key="name"
      header-type-key="type"
      :row-height="32"
      :col-initial-width="252"
      :col-resize="false"
    />
    <SlButton
      v-if="hasBtn"
      class="error-block__button"
      variant="text"
      color="grey"
      @click.stop="isOpen = !isOpen"
    >
      {{ btnLabel }}
      <template #append>
        <icon
          data="@icons/chevron_down.svg"
          class="fill-off size-16"
          :class="{
            'rotate-180': isOpen
          }"
        />
      </template>
    </SlButton>
  </SlAccordion>
</template>

<script>
import { toArray } from '@/helpers/utils/toArray';

export default {
  name: 'ErrorBlock',
  props: {
    title: {
      type: String,
      default: ''
    },
    table: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isOpen: false,
      visibleLimit: 3
    };
  },
  computed: {
    hasBtn() {
      return this.table.totalRowsCount > this.visibleLimit;
    },
    btnLabel() {
      return this.isOpen
        ? this.$t('Web.InTransition.ViewLess')
        : this.$t('Web.InTransition.ViewMore');
    },
    rowsNumberToDisplay() {
      return this.isOpen ? this.table.totalRowsCount : this.visibleLimit;
    },
    tableHeaders() {
      return toArray(this.table.header);
    },
    tableRows() {
      return toArray(this.table.body).slice(0, this.rowsNumberToDisplay);
    }
  },
  methods: {
    isItemVisible(idx) {
      return this.isOpen || idx <= this.visibleLimit - 1;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/utils/_color.scss";

.error-block {
  display: flex;
  flex-direction: column;

  &__button {
    margin: 8px 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .error-block__title {
    display: flex;
    align-items: center;
    flex-basis: 100%;
    column-gap: 10px;

    .sl-svg-icon {
      min-width: 20px;
      max-width: 20px;

      &:last-child {
        margin-left: auto;
      }
    }
  }
}
</style>
