var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SlAccordion',{staticClass:"error-block",attrs:{"full-height":""},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('div',{staticClass:"error-block__title body-3 grey-80",on:{"click":function($event){_vm.tableRows.length && toggle()}}},[_c('icon',{staticClass:"fill-off size-20 color-accent-red-90",attrs:{"data":require("@icons/error_circle.svg")}}),_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.tableRows.length)?_c('icon',{staticClass:"fill-off size-20",class:{
          'rotate-180': isOpen
        },attrs:{"data":require("@icons/chevron_down.svg")}}):_vm._e()],1)]}}])},[(_vm.tableRows.length)?_c('SlTable',{attrs:{"id":_vm.title,"headers":_vm.tableHeaders,"rows":_vm.tableRows,"unique-key":"id","header-unique-key":"key","header-name-key":"name","header-type-key":"type","row-height":32,"col-initial-width":252,"col-resize":false}}):_vm._e(),(_vm.hasBtn)?_c('SlButton',{staticClass:"error-block__button",attrs:{"variant":"text","color":"grey"},on:{"click":function($event){$event.stopPropagation();_vm.isOpen = !_vm.isOpen}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('icon',{staticClass:"fill-off size-16",class:{
          'rotate-180': _vm.isOpen
        },attrs:{"data":require("@icons/chevron_down.svg")}})]},proxy:true}],null,false,4206227205)},[_vm._v(" "+_vm._s(_vm.btnLabel)+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }