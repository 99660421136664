<template>
  <SlModal
    :id="id"
    :title="title"
    @created="onCreated"
    @on-enter="handleConfirm"
    @hide="onModalHide"
  >
    <div class="modal-content errors-notify">
      <ErrorBlock
        v-for="(error, idx) in errorsData"
        :key="idx"
        :title="error.title || $t(error.label) || error.label"
        :table="error.table"
      />
    </div>

    <template #footer>
      <SlModalFooter>
        <SlButton
          full-width
          @click="handleConfirm"
        >
          {{ $t('Common.Ok') }}
        </SlButton>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { modal } from '@/mixins/modal';
import ErrorBlock from '@/components/Modals/Shared/ErrorsNotifyModal/ErrorBlock';

export default {
  name: 'ErrorsNotifyModal',
  components: {
    ErrorBlock
  },
  mixins: [modal],
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      title: '',
      errorsData: [],
      confirmCallback: null
    };
  },
  methods: {
    onModalHide() {
      this.title = '';
      this.errorsData = [];
      this.confirmCallback = null;
    },
    handleConfirm() {
      if (this.confirmCallback) {
        this.confirmCallback();
      }

      this.hideModal(this.id);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/style/utils/_mixins.scss";

.modal-content.errors-notify {
  max-height: 500px;
  min-height: 50px;
}
</style>
